import React from "react"
import { Toaster } from "react-hot-toast"
import MyCalendar from "./pages/Calendar"

export default function App() {
  return (
    <div>
      <MyCalendar />
      <Toaster />
    </div>
  )
}
