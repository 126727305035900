import "./DeleteModal.css"

const DeleteModal = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null

  return (
    <div className="delete-modal-overlay">
      <div className="delete-modal">
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to delete this dispatch?</p>
        <div className="modal-actions">
          <button className="confirm-delete" onClick={onSubmit}>
            Delete
          </button>
          <button className="cancel" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal
