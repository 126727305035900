import { MoreVertical, Plus } from "lucide-react"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import apiRequest from "../../api/apiService"
import Modal from "../Dispatch/components/Modal"
import DeleteModal from "./components/DeleteModal"
import "./index.css"

const Dispatch = () => {
  const [dispatches, setDispatches] = useState([])
  const [nextPage, setNextPage] = useState(null)
  const [prevPage, setPrevPage] = useState(null)
  const [activeMenu, setActiveMenu] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedDispatch, setSelectedDispatch] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [readOnly, setReadOnly] = useState(false)

  const fetchDispatches = async (url = "/users/user-dispatch/") => {
    if (!url) return
    try {
      const response = await apiRequest("get", url)
      setDispatches(response?.results || [])
      setNextPage(response?.next || null)
      setPrevPage(response?.previous || null)
    } catch (error) {
      console.error("Failed to load dispatches:", error)
    }
  }

  useEffect(() => {
    fetchDispatches()
  }, [])

  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.closest(".menu-container")) {
        setActiveMenu(null)
      }
    }
    document.addEventListener("click", handleClickOutside)
    return () => document.removeEventListener("click", handleClickOutside)
  }, [])

  const toggleMenu = (id, event) => {
    event.stopPropagation()
    setActiveMenu(activeMenu === id ? null : id)
  }

  const toggleViewModal = dispatch => {
    setSelectedDispatch(dispatch)
    setReadOnly(true)
    setIsEditMode(false)
    setIsModalOpen(true)
  }

  const toggleEditModal = dispatch => {
    setSelectedDispatch(dispatch)
    setIsEditMode(true)
    setReadOnly(false)
    setIsModalOpen(true)
  }

  const handleAddDispatch = async data => {
    try {
      await apiRequest("post", "/users/user-dispatch/", data)
      fetchDispatches()
      setIsModalOpen(false)
      toast.success("Dispatch added successfully")
    } catch (error) {
      console.error("Failed to add dispatch:", error)
      toast.error("Failed to add dispatch")
    }
  }

  const handleUpdateDispatch = async data => {
    if (!selectedDispatch) return
    try {
      await apiRequest(
        "put",
        `/users/user-dispatch/${selectedDispatch.id}/`,
        data
      )
      fetchDispatches()
      setIsModalOpen(false)
      setIsEditMode(false)
      setSelectedDispatch(null)
      toast.success("Dispatch updated successfully")
    } catch (error) {
      console.error("Failed to update dispatch:", error)
      toast.error("Failed to update dispatch")
    }
  }

  const handleDeleteDispatch = async () => {
    if (!selectedDispatch) return
    try {
      await apiRequest("delete", `/users/user-dispatch/${selectedDispatch.id}/`)
      fetchDispatches()
      setIsDeleteModalOpen(false)
      toast.success("Dispatch deleted successfully")
    } catch (error) {
      console.error("Failed to delete dispatch:", error)
      toast.error("Failed to delete dispatch")
    }
  }

  return (
    <div className="dispatch-container">
      <div className="dispatch-header">
        <h2>Dispatches</h2>
        <button
          className="add-btn"
          onClick={() => {
            setIsModalOpen(true)
            setIsEditMode(false)
            setReadOnly(false)
            setSelectedDispatch(null)
          }}
        >
          <Plus className="icon" /> Add New Dispatch
        </button>
      </div>

      <div className="dispatch-grid">
        {dispatches.length > 0 ? (
          dispatches.map(({ id, ...dispatch }) => (
            <div key={id} className="dispatch-card">
              <div className="dispatch-card-header">
                <div>
                  <h3>{dispatch.contact_name}</h3>
                  <p>{dispatch.contact_number}</p>
                </div>
                <div className="menu-container">
                  <button className="menu-btn" onClick={e => toggleMenu(id, e)}>
                    <MoreVertical className="icon" />
                  </button>
                  {activeMenu === id && !isModalOpen && !isDeleteModalOpen && (
                    <div className="menu-dropdown">
                      <button
                        onClick={() => toggleViewModal({ ...dispatch, id })}
                      >
                        View
                      </button>
                      <button
                        onClick={() => toggleEditModal({ ...dispatch, id })}
                      >
                        Edit
                      </button>
                      <button
                        className="delete"
                        onClick={() => {
                          setSelectedDispatch({ id })
                          setIsDeleteModalOpen(true)
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="dispatch-details">
                <p>
                  <strong>Pickup:</strong> {dispatch.pickup_location}
                </p>
                <p>
                  <strong>Dropoff:</strong> {dispatch.dropoff_location}
                </p>
                <p>
                  <strong>Truck:</strong> {dispatch.truck} |{" "}
                  <strong>Trailer:</strong> {dispatch.trailer}
                </p>
                <p>
                  <strong>Load:</strong> {dispatch.load}
                </p>
                <p>
                  <strong>Permit:</strong>
                  <a
                    href={dispatch.permit}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    View Permit
                  </a>
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="no-data">No dispatches found.</p>
        )}
      </div>

      {dispatches.length > 0 && (
        <div className="pagination">
          <button
            onClick={() => prevPage && fetchDispatches(prevPage)}
            disabled={!prevPage}
          >
            Previous
          </button>
          <button
            onClick={() => nextPage && fetchDispatches(nextPage)}
            disabled={!nextPage}
          >
            Next
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setIsEditMode(false)
          setReadOnly(false)
          setSelectedDispatch(null)
        }}
        onSubmit={isEditMode ? handleUpdateDispatch : handleAddDispatch}
        initialData={selectedDispatch}
        readOnly={readOnly}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={handleDeleteDispatch}
      />
    </div>
  )
}

export default Dispatch
