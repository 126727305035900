import React, { useEffect, useState } from "react"
import apiRequest from "../../../api/apiService"
import "./Modal.css"

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = null,
  readOnly = false
}) => {
  const [formData, setFormData] = useState({
    contact_name: "",
    contact_number: "",
    pickup_location: "",
    dropoff_location: "",
    tasks: "",
    truck: "",
    trailer: "",
    load: "",
    permit: null,
    special_instructions: "",
    users: []
  })

  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const url = "/api/v1/users/list/"
        const response = await apiRequest("get", url)
        setUsers(response)
      } catch (error) {
        console.error("Error fetching users:", error)
      }
    }

    fetchUsers()
  }, [])

  useEffect(() => {
    if (initialData) {
      console.log("Editing Dispatch", initialData)
      setFormData({
        ...initialData,
        users: initialData.users ? initialData.users.map(user => user.pk) : [],
        permit: null
      })
    } else {
      console.log("Creating New Dispatch")
      setFormData({
        contact_name: "",
        contact_number: "",
        pickup_location: "",
        dropoff_location: "",
        tasks: "",
        truck: "",
        trailer: "",
        load: "",
        permit: null,
        special_instructions: "",
        users: []
      })
    }
  }, [initialData])

  if (!isOpen) return null

  const handleChange = e => {
    if (readOnly) return
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleFileChange = e => {
    if (readOnly) return
    setFormData(prev => ({ ...prev, permit: e.target.files[0] }))
  }

  const handleMultiSelectChange = e => {
    if (readOnly) return
    const selectedValues = Array.from(
      e.target.selectedOptions,
      opt => opt.value
    )
    setFormData(prev => ({
      ...prev,
      users: selectedValues
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (readOnly) return

    const data = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "users") {
        value.forEach(userId => data.append(key, userId))
      } else if (key === "permit" && !value) {
        // Skip permit if it's null (not updated)
        return
      } else {
        data.append(key, value)
      }
    })

    if (initialData?.id) {
      data.append("id", initialData.id)
    }

    onSubmit(data)
  }

  console.log("formData.users==", formData.users)

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>
            {readOnly
              ? "View Dispatch"
              : initialData
                ? "Edit Dispatch"
                : "Add New Dispatch"}
          </h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <label>
              Contact Name:
              <input
                type="text"
                name="contact_name"
                value={formData.contact_name}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>
            <label>
              Contact Number:
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>
            <label>
              Pickup Location:
              <input
                type="text"
                name="pickup_location"
                value={formData.pickup_location}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>
            <label>
              Dropoff Location:
              <input
                type="text"
                name="dropoff_location"
                value={formData.dropoff_location}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>

            <label className="form-label">
              Tasks:
              <textarea
                name="tasks"
                value={formData.tasks}
                onChange={handleChange}
                required
                disabled={readOnly}
                className="custom-textarea"
              ></textarea>
            </label>

            <label>
              Truck:
              <input
                type="text"
                name="truck"
                value={formData.truck}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>
            <label>
              Trailer:
              <input
                type="text"
                name="trailer"
                value={formData.trailer}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>
            <label>
              Load:
              <input
                type="text"
                name="load"
                value={formData.load}
                onChange={handleChange}
                required
                disabled={readOnly}
              />
            </label>
            <label>
              Permit:
              <input
                type="file"
                name="permit"
                onChange={handleFileChange}
                disabled={readOnly}
              />
              {initialData?.permit && <p>Current: {initialData.permit}</p>}
            </label>
            <label className="form-label">
              Special Instructions:
              <textarea
                name="special_instructions"
                value={formData.special_instructions}
                onChange={handleChange}
                disabled={readOnly}
                className="custom-textarea"
              ></textarea>
            </label>
            <label>
              Users:
              <div className="custom-dropdown">
                <select
                  name="users"
                  multiple
                  value={formData.users}
                  onChange={handleMultiSelectChange}
                  required
                  disabled={readOnly}
                >
                  {users.map(user => (
                    <option key={user.pk} value={user.pk}>
                      {user.email}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
          {!readOnly && (
            <div className="modal-footer">
              <button type="submit" className="submit-btn">
                {initialData ? "Update Dispatch" : "Add Dispatch"}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default Modal
